import {createLogic} from 'redux-logic';
import {SET_COMMISSION_FILTERS, SET_COMMISSION_FILTERS_SUCCESS, SET_COMMISSION_FILTERS_FAILED} from '../action';
import moment from "moment";

const LOCAL_STORE_KEY_IDS = "TP_COMMISSION_filterIds";
const LOCAL_STORE_KEY_FROM = "TP_COMMISSION_dateFilterFrom";
const LOCAL_STORE_KEY_UNTIL = "TP_COMMISSION_dateFilterUntil";

async function getLocalStoredFilters() {
    const ids = await localStorage.getItem(LOCAL_STORE_KEY_IDS),
        from = await localStorage.getItem(LOCAL_STORE_KEY_FROM),
        until = await localStorage.getItem(LOCAL_STORE_KEY_UNTIL);

    return {
        storedIds: ids ? JSON.parse(ids) : [],
        storedFrom: from,
        storedUntil: until
    };
}

export default createLogic({
    type: SET_COMMISSION_FILTERS,
    latest: true,

    processOptions: {
        dispatchReturn: true,
        successType: SET_COMMISSION_FILTERS_SUCCESS,
        failType: SET_COMMISSION_FILTERS_FAILED,
    },

    process({action}) {
        return getLocalStoredFilters().then(stored => {
            const {storedIds, storedFrom, storedUntil} = stored,
                ids = action.payload.ids || storedIds || [],
                from = action.payload.from || storedFrom || null,
                until = action.payload.until || storedUntil || null,
                fromMoment = from ? moment(from) : moment().startOf("day").subtract(7, "days"),
                untilMoment = until ? moment(until) : moment().endOf("day");

            if(action.payload.ids) localStorage.setItem(LOCAL_STORE_KEY_IDS, JSON.stringify(ids));
            if(action.payload.from) localStorage.setItem(LOCAL_STORE_KEY_FROM, fromMoment.toISOString());
            if(action.payload.until) localStorage.setItem(LOCAL_STORE_KEY_UNTIL, untilMoment.toISOString());

            return {
                filterIds: ids,
                filterFrom: fromMoment,
                filterUntil: untilMoment
            };
        });
    }
});