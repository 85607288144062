import {createLogic} from 'redux-logic';
import {LOAD_WORKLOGS_SUCCESS, LOAD_MEETINGS_SUCCESS, LOAD_MEETINGS_FAILED} from '../action';
import {loadMeetings} from "../../service/api";

export default createLogic({
    type: LOAD_WORKLOGS_SUCCESS,
    latest: true,

    processOptions: {
        dispatchReturn: true,
        successType: LOAD_MEETINGS_SUCCESS,
        failType: LOAD_MEETINGS_FAILED,
    },
    process({getState}) {
        const state = getState();

        return loadMeetings(
            state.auth.token,
            state.worktime.filterFrom,
            state.worktime.filterUntil
        );
    }
});