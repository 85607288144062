export const SET_WORKTIME_FILTERS = 'SET_WORKTIME_FILTERS';
export const SET_WORKTIME_FILTERS_SUCCESS = 'SET_WORKTIME_FILTERS_SUCCESS';
export const SET_WORKTIME_FILTERS_FAILED = 'SET_WORKTIME_FILTERS_FAILED';
export const LOAD_WORKLOGS = 'LOAD_WORKLOGS';
export const LOAD_WORKLOGS_SUCCESS = 'LOAD_WORKLOGS_SUCCESS';
export const LOAD_WORKLOGS_FAILED = 'LOAD_WORKLOGS_FAILED';
export const LOAD_MEETINGS = 'LOAD_MEETINGS';
export const LOAD_MEETINGS_SUCCESS = 'LOAD_MEETINGS_SUCCESS';
export const LOAD_MEETINGS_FAILED = 'LOAD_MEETINGS_FAILED';
export const DOWNLOAD_WORKLOGS_AS_CSV = 'DOWNLOAD_WORKLOGS';
export const DOWNLOAD_WORKLOGS_AS_CSV_SUCCESS = 'DOWNLOAD_WORKLOGS_AS_CSV_SUCCESS';
export const DOWNLOAD_WORKLOGS_AS_CSV_FAILED = 'DOWNLOAD_WORKLOGS_AS_CSV_FAILED';

const setFilters = (from, until) => ({
    type: SET_WORKTIME_FILTERS,
    payload: {
        from: from,
        until: until
    }
});

const downloadWorklogsAsCsv = () => ({
    type: DOWNLOAD_WORKLOGS_AS_CSV
});

export const actions = {
    setFilters,
    downloadWorklogsAsCsv
};

