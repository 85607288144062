export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

const openMenu = () => ({
    type: OPEN_MENU
});

const closeMenu = () => ({
    type: CLOSE_MENU
});

export const actions = {
    openMenu,
    closeMenu
};

