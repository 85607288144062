import {createLogic} from 'redux-logic';
import {SET_WORKTIME_FILTERS, SET_WORKTIME_FILTERS_SUCCESS, SET_WORKTIME_FILTERS_FAILED} from '../action';
import moment from "moment";

const LOCAL_STORE_KEY_FROM = "TP_WORKTIME_dateFilterFrom";
const LOCAL_STORE_KEY_UNTIL = "TP_WORKTIME_dateFilterUntil";

async function getLocalStoredFilters() {
    const storedFrom = await localStorage.getItem(LOCAL_STORE_KEY_FROM),
        storedUntil = await localStorage.getItem(LOCAL_STORE_KEY_UNTIL);

    return {storedFrom, storedUntil};
}

export default createLogic({
    type: SET_WORKTIME_FILTERS,
    latest: true,

    processOptions: {
        dispatchReturn: true,
        successType: SET_WORKTIME_FILTERS_SUCCESS,
        failType: SET_WORKTIME_FILTERS_FAILED,
    },

    process({action}) {
        return getLocalStoredFilters().then(stored => {
            const {storedFrom, storedUntil} = stored,
                from = action.payload.from || storedFrom || null,
                until = action.payload.until || storedUntil || null,
                fromMoment = from ? moment(from) : moment().startOf("day").subtract(7, "days"),
                untilMoment = until ? moment(until) : moment().endOf("day");

            if(action.payload.from) localStorage.setItem(LOCAL_STORE_KEY_FROM, fromMoment.toISOString());
            if(action.payload.until) localStorage.setItem(LOCAL_STORE_KEY_UNTIL, untilMoment.toISOString());

            return {
                filterFrom: fromMoment,
                filterUntil: untilMoment
            };
        });
    }
});