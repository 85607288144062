import {createStore, applyMiddleware, compose} from 'redux';

import {createLogicMiddleware} from 'redux-logic';
import thunk from 'redux-thunk';
import config from '../config';
import logics from './logic'
import combinedReducers from './reducer';

const logicDeps = {
	// optional injected dependencies for logic
	// anything you need to have available in your logic
	cfg: config
};

export default function configureStore(onCompletion) {
	const logicMiddleware = createLogicMiddleware(logics, logicDeps);
	const middleware = applyMiddleware(logicMiddleware, thunk);
	return createStore(combinedReducers, compose(middleware));
}
