import {createLogic} from 'redux-logic';
import {LOAD_WORKLOGS_SUCCESS, LOAD_WORKLOGS_FAILED, SET_WORKTIME_FILTERS_SUCCESS} from '../action';
import {loadWorklogs} from "../../service/api";

export default createLogic({
    type: SET_WORKTIME_FILTERS_SUCCESS,
    latest: true,

    processOptions: {
        dispatchReturn: true,
        successType: LOAD_WORKLOGS_SUCCESS,
        failType: LOAD_WORKLOGS_FAILED,
    },
    process({getState}) {
        const state = getState();

        return loadWorklogs(
            state.auth.token,
            state.worktime.filterFrom,
            state.worktime.filterUntil);
    }
});