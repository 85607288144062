import {OPEN_CONSOLE, CLOSE_CONSOLE} from './action';

const initialState = {
    open: false
};

export default function consoleReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_CONSOLE:
            state = {...state, open: true};
            break;
        case CLOSE_CONSOLE:
            state = {...state, open: false};
            break;
        default:
            break;
    }
    return state;
}