import {createLogic} from 'redux-logic';
import {DOWNLOAD_WORKLOGS_AS_CSV, DOWNLOAD_WORKLOGS_AS_CSV_SUCCESS, DOWNLOAD_WORKLOGS_AS_CSV_FAILED} from '../action';
import {downloadWorklogsAsCsv} from "../../service/api";

export default createLogic({
    type: DOWNLOAD_WORKLOGS_AS_CSV,
    latest: true,

    processOptions: {
        dispatchReturn: true,
        successType: DOWNLOAD_WORKLOGS_AS_CSV_SUCCESS,
        failType: DOWNLOAD_WORKLOGS_AS_CSV_FAILED,
    },
    process({getState}) {
        const state = getState();

        return downloadWorklogsAsCsv(
            state.auth.token,
            state.worktime.filterFrom,
            state.worktime.filterUntil
        );
    }
});