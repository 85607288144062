import React, {Component} from 'react';
import moment from "moment";
import {DatePicker, Dropdown, Button, Menu} from 'antd';
import {CalendarOutlined} from '@ant-design/icons';

const {RangePicker} = DatePicker;

const DATE_FORMAT = "DD. MMM",
    predefinedRanges = {
        "heute": [
            moment().startOf("day"),
            moment().endOf("day")
        ],
        "gestern": [
            moment().subtract(1, "day").startOf("day"),
            moment().subtract(1, "day").endOf("day")
        ],
        "Aktuelle Woche": [
            moment().startOf("isoWeek").startOf("day"),
            moment().endOf("isoWeek").endOf("day")
        ],
        "Vergangene Woche": [
            moment().subtract(1, "week").startOf("isoWeek").startOf("day"),
            moment().subtract(1, "week").endOf("isoWeek").endOf("day")
        ],
        [moment().subtract(1, "month").format("MMM yy")]: [
            moment().subtract(1, "month").startOf("month").startOf("day"),
            moment().subtract(1, "month").endOf("month").endOf("day")
        ],
        [moment().format("MMM yy")]: [
            moment().startOf("month"),
            moment().endOf("month")
        ]
    };

export class DateRangePicker extends Component {
    render() {
        return (
            <RangePicker {...this.props}
                         format={DATE_FORMAT}
                         ranges={predefinedRanges}
                         allowClear={false}/>
        );

    }
}

export class DateRangePickerMobile extends Component {
    render() {
        const {from, until, onClick} = this.props,
            menu = (
                <Menu
                    onClick={(i) =>
                        onClick(
                            predefinedRanges[i.key][0],
                            predefinedRanges[i.key][1]
                        )}>
                    {Object.keys(predefinedRanges).map(key => <Menu.Item key={key}>{key}</Menu.Item>)}
                </Menu>
            );

        return (
            <Dropdown overlay={menu} {...this.props}>
                <Button icon={<CalendarOutlined/>}>
                    {from ? from.format(DATE_FORMAT) : ""} - {until ? until.format(DATE_FORMAT) : ""}
                </Button>
            </Dropdown>
        );
    }
}
