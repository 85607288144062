import React, {Component} from 'react';
import Logo from '../../resources/logo.png'
import {Affix, Col, Row} from 'antd';
import styles from "./Header.module.css";
import MenuBtn from "../layout/Menu";


export default class extends Component {

    render() {
        return (
            <Affix>
                <Row className={`${styles.row}`}>
                    <Col xs={0} sm={0} md={24}>
                        <Row className={`${styles.col}`}>
                            <Col md={2} xxl={1}>
                                <MenuBtn/>
                            </Col>
                            <Col md={20} xxl={22} className={`${styles.filter}`}>
                                {React.Children.only(this.props.desktop)}
                            </Col>
                            <Col md={2} xxl={1} className={`${styles.logo}`}>
                                <img src={Logo} alt="logo" height={30}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={0} className={`${styles.col} ${styles.mobile}`}>
                        <Row>
                            <Col xs={2} sm={12} className={`${styles.logo} ${styles.mobile}`}>
                                <img src={Logo} alt="logo" height={30}/>
                            </Col>
                            <Col xs={20} sm={12} className={`${styles.menu} ${styles.mobile}`}>
                                <MenuBtn/>
                            </Col>
                        </Row>
                        <Col className={`${styles.filter} ${styles.mobile}`}>
                            {React.Children.only(this.props.mobile)}
                        </Col>
                    </Col>
                </Row>
            </Affix>
        );
    }
}

