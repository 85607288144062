export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

const loginByUrl = () => ({
    type: LOGIN
});

export const actions = {
    loginByUrl
};