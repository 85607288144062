import React, {Component} from 'react';
import {actions} from "./redux/action";
import {connect} from "react-redux";
import moment from "moment";
import {Button, Col, TreeSelect} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';
import Header from "../layout/Header";
import {DateRangePicker, DateRangePickerMobile} from "../_partial/RangePicker";
import styles from "./Header.module.css";

const {TreeNode} = TreeSelect;

class Head extends Component {

    componentDidMount() {
        this.props.loadAll();
        this.props.setFilters();
    }

    render() {
        const {commissions, ids, from, until, setFilters} = this.props,
            select = (
                <TreeSelect
                    className={styles.select}
                    showSearch
                    style={{width: '100%'}}
                    value={ids}
                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                    placeholder="Bitte auswählen"
                    multiple
                    // treeDefaultExpandAll
                    treeNodeFilterProp={"title"}
                    onChange={value => setFilters(value)}
                    onSearch={value => this.setState({search: value})}>
                    {commissions.map(c =>
                        <TreeNode value={c.id} title={`[${c.issue.key}] ${c.issue.name}`}>
                            {c.children.map(p =>
                                <TreeNode value={p.id} title={`[${p.issue.key}] ${p.issue.name}`}/>
                            )}
                            {/*<TreeNode value="sss" title={<b style={{ color: '#08c' }}>sss</b>} />*/}
                        </TreeNode>
                    )}
                </TreeSelect>);

        return (
            <Header
                desktop={
                    <div className={styles.wrapper}>
                        {select}
                        <DateRangePicker
                            key={"rangePicker"}
                            className={`${styles.btn}`}
                            value={[moment(from), moment(until)]}
                            onCalendarChange={(dates) => {
                                const dateArr = dates || [],
                                    from = dateArr[0] || null,
                                    until = dateArr[1] ? moment(dateArr[1]).endOf("day") : null;
                                setFilters(null, from, until)
                            }}/>
                        {this.getReloadBtn()}
                    </div>
                }
                mobile={
                    <div  className={`${styles.wrapper} ${styles.mobile}`}>
                        {select}
                        <DateRangePickerMobile
                            className={`${styles.btn}`}
                            from={from}
                            until={until}
                            onClick={(from, until) => setFilters(null, from, until)}/>
                        {this.getReloadBtn()}
                    </div>}/>
        );

    }

    getReloadBtn = () => {
        const {from, until, loading, setFilters} = this.props,
            btnProps = {};

        if (loading)
            btnProps.loading = true;

        return (
            <Button key={"reloadBtn"}
                    className={`${styles.btn}`}
                    {...btnProps}
                    icon={<ReloadOutlined/>}
                    onClick={() => setFilters(null, from, until)}/>
        );
    }
}

function bindAction(dispatch) {
    return {
        loadAll: () => dispatch(actions.loadAll()),
        setFilters: (ids, from, until) => dispatch(actions.setFilters(ids, from, until))
    };
}

const mapStateToProps = state => ({
    ids: state.commission.filterIds,
    from: state.commission.filterFrom,
    until: state.commission.filterUntil,
    loading: state.commission.loading,
    commissions: state.commission.commissions
});

export default connect(mapStateToProps, bindAction)(Head);
