export function loadCommissions(token) {
    return fetch(`/api/commissions`, {
        method: 'GET',
        credentials: 'omit',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((res) => {
        return res.json()
    }).catch(e => {
        return [];
    });
}

export function loadCommissionsWithFilter(token, ids, from, until) {
    return fetch(`/api/commissions/filter?ids=${ids}&from=${from.toISOString()}&until=${until.toISOString()}`, {
        method: 'GET',
        credentials: 'omit',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((res) => {
        return res.json()
    }).catch(e => {
        return [];
    });
}
