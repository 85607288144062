import {LOGIN_SUCCESS, LOGIN_FAILED} from './action';

const initialState = {
    token: null
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            state = {...state, token: action.payload};
            break;
        case LOGIN_FAILED:
            state = {...state, token: null};
            break;
        default:
            break;
    }
    return state;
}