export const SET_COMMISSION_FILTERS = 'SET_COMMISSION_FILTERS';
export const SET_COMMISSION_FILTERS_SUCCESS = 'SET_COMMISSION_FILTERS_SUCCESS';
export const SET_COMMISSION_FILTERS_FAILED = 'SET_COMMISSION_FILTERS_FAILED';
export const LOAD_ALL_COMMISSIONS = 'LOAD_ALL_COMMISSIONS';
export const LOAD_ALL_COMMISSIONS_SUCCESS = 'LOAD_ALL_COMMISSIONS_SUCCESS';
export const LOAD_ALL_COMMISSIONS_FAILED = 'LOAD_ALL_COMMISSIONS_FAILED';
export const LOAD_FILTERED_COMMISSIONS = 'LOAD_FILTERED_COMMISSIONS';
export const LOAD_FILTERED_COMMISSIONS_SUCCESS = 'LOAD_FILTERED_COMMISSIONS_SUCCESS';
export const LOAD_FILTERED_COMMISSIONS_FAILED = 'LOAD_FILTERED_COMMISSIONS_FAILED';

const loadAll = () => ({
    type: LOAD_ALL_COMMISSIONS
});

const setFilters = (ids, from, until) => ({
    type: SET_COMMISSION_FILTERS,
    payload: {
        ids: ids,
        from: from,
        until: until
    }
});

export const actions = {
    loadAll,
    setFilters
};

