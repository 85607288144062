import moment from "moment";

export const ROW_TYPES = {
    WORKLOG: "WORKLOG",
    MEETING: "MEETING"
};

export const mapWorklogsAndMeetingstoGrid = (worklogs, meetings) => {
    const workByDay = getWorkLogsByDay(worklogs),
        meetingsByDay = getMeetingsByDay(meetings),
        allDays = [
            ...new Set([
                ...Object.keys(workByDay.worklogsByDayWithRowsAndTime),
                ...Object.keys(meetingsByDay)
            ])
        ],
        gridRows = [];

    gridRows.push({
        group: true,
        title: "Gesamt",
        description: "",
        durationSeconds: workByDay.sumAllTimeSpentSeconds,
        id: "summary"
    });

    allDays.sort((a, b) => moment(a).toDate() - moment(b).toDate()).reverse()
    allDays.forEach(day => {
        let workOnThatDay = workByDay.worklogsByDayWithRowsAndTime[day],
            meetingsOnThatDay = meetingsByDay[day];

        gridRows.push({
            group: true,
            title: moment(day).format("dd DD. MMM"),
            description: "",
            durationSeconds: workOnThatDay ? workOnThatDay.sumTimeSpentSeconds : "",
            id: `day_${day}`
        });

        let stuffOnThatDay = [];
        if(workOnThatDay) workOnThatDay.rows.forEach(w => stuffOnThatDay.push(mapWorklog(w)));
        if(meetingsOnThatDay) meetingsOnThatDay.forEach(m => stuffOnThatDay.push(mapMeeting(m)));
        stuffOnThatDay.sort((a, b) => moment(a.start).toDate() - moment(b.start).toDate()).reverse();
        stuffOnThatDay.forEach(s => gridRows.push(s))
    });

    return gridRows;
}

const getWorkLogsByDay = (worklogs) => {
    let worklogsByDay = {},
        worklogsByDayWithRowsAndTime = {},
        totalWorkTime = 0;

    worklogs.forEach(w => {
        let day = moment(w.started).format("YYYY-MM-DD")
        return worklogsByDay[day] = [...(worklogsByDay[day] || []), w]
    });

    Object.keys(worklogsByDay).forEach(day => {
        let worklogs = worklogsByDay[day],
            workTimeOfDay = worklogs.reduce((acc, w) => acc + w.timeSpentSeconds, 0);

        worklogsByDayWithRowsAndTime[day] = {
            sumTimeSpentSeconds: workTimeOfDay,
            rows: worklogs
        }
        totalWorkTime += workTimeOfDay;
    });

    return {
        sumAllTimeSpentSeconds: totalWorkTime,
        worklogsByDayWithRowsAndTime: worklogsByDayWithRowsAndTime
    };
}

const getMeetingsByDay = (meetings) => {
    let meetingsByDay = {};

    meetings.forEach(m => {
        let day = moment(m.start).format("YYYY-MM-DD");
        return meetingsByDay[day] = [...(meetingsByDay[day] || []), m]
    });

    return meetingsByDay;
}

const mapWorklog = (w) => {
    return {
        type: ROW_TYPES.WORKLOG,
        title: `[${w.issue.key}] ${w.issue.name}`,
        description: w.comment || "",
        durationSeconds: w.timeSpentSeconds,
        start: w.started,
        end: w.ended,
        id: w.id,
        key: w.issue.key,
        baseUrl: w.platform.baseUrl
    };
}

const mapMeeting = (m) => {
    return {
        type: ROW_TYPES.MEETING,
        title: `[${m.issue.key}] ${m.issue.name}`,
        description: "",
        durationSeconds: m.durationSeconds,
        start: m.start,
        end: m.end,
        id: m.issue.id,
        key: m.issue.key,
        baseUrl: m.platform.baseUrl
    }
}