import React, {Component} from 'react'
import {Provider} from 'react-redux';
import './App.css'
import Secured from "./modules/auth/Index";
import Router from "./modules/router/Index";
import * as moment from "moment-timezone";
import {ConfigProvider} from 'antd';
import "moment/locale/de";
import deDE from 'antd/lib/locale/de_DE';
import configureStore from "./redux/configureStore";

moment.tz.setDefault("Europe/Amsterdam");

export default class App extends Component {
    store = null;

    render() {
        this.store = configureStore();

        return (
            <Provider store={this.store}>
                <Secured>
                    <ConfigProvider locale={deDE}>
                        <Router/>
                    </ConfigProvider>
                </Secured>
            </Provider>
        );
    }

}
