import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {actions} from './redux/action';
import {Result} from "antd";

class Index extends Component {

    componentDidMount = () => {
        const {actions} = this.props;
        actions.loginByUrl();
    }

    render() {
        const {token} = this.props;
        if (!token)
            return (
                <Result status="403"
                        title="403"
                        subTitle="Sorry, you are not authorized to access this page."/>
            );

        return React.Children.only(this.props.children);
    }
}

function bindActions(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

const mapStateToProps = state => ({
    token: state.auth.token
});

export default connect(mapStateToProps, bindActions)(Index);
