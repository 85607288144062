import React, {Component} from 'react'
import styles from './Menu.module.css'
import {ROUTES} from "../router/Index";
import {Button, Col, Drawer, Popover, Row, Typography} from "antd";
import {ClockCircleOutlined, FileTextOutlined, MenuOutlined} from "@ant-design/icons";
import {GlobalHotKeys} from "react-hotkeys";
import {connect} from "react-redux";
import {actions} from "./redux/action";
import {withRouter} from "react-router-dom";

const {Title} = Typography;


class Menu extends Component {
    menuItems = [
        {
            name: "Zeiterfassung",
            icon: <ClockCircleOutlined/>,
            route: ROUTES.worktime,
            hotkeys: "shift w"
        },
        {
            name: "Aufträge",
            icon: <FileTextOutlined/>,
            route: ROUTES.commission,
            hotkeys: "shift c"
        }
    ];

    render() {
        const {menuOpen} = this.props;

        return (
            <div className={styles.menuWrapper}>
                <Button ghost
                        className={styles.menuBtn}
                        icon={<MenuOutlined/>}
                        onClick={() => this.props.openMenu()}/>
                <Drawer
                    placement={"top"}
                    closable={false}
                    onClose={() => this.props.closeMenu()}
                    visible={menuOpen}>
                    <Row>
                        {this.menuItems.map(m =>
                            <Col xs={24} md={2} key={`${m.route}-col`}
                                 className={styles.menuRow}
                                 onClick={() => this.goTo(m.route)}>
                                <Popover content={m.name}>
                                    <Row>
                                        <Col xs={4} md={24} className={styles.menuItem}>
                                            {m.icon}
                                        </Col>
                                        <Col xs={20} md={0} key={`${m.route}-col`}>
                                            <Title>{m.name}</Title>
                                        </Col>
                                    </Row>
                                </Popover>
                            </Col>)}
                    </Row>
                </Drawer>
                <GlobalHotKeys
                    keyMap={{
                        PREV: "shift left",
                        NEXT: "shift right",
                        WORKTIME: "shift w",
                        COMMISSION: "shift c"
                    }}
                    handlers={{
                        PREV: this.prevMenuItem,
                        NEXT: this.nextMenuItem,
                        WORKTIME: () => this.goTo(ROUTES.worktime),
                        COMMISSION: () => this.goTo(ROUTES.commission)
                    }}/>
            </div>
        );
    }

    prevMenuItem = () => {
        const currentPath = this.props.location.pathname,
            currentIndex = this.menuItems.findIndex(m => m.route === currentPath),
            prevMenuItem = this.menuItems[currentIndex - 1] || this.menuItems[this.menuItems.length - 1];

        this.goTo(prevMenuItem.route);
    }

    nextMenuItem = () => {
        const currentPath = this.props.location.pathname,
            currentIndex = this.menuItems.findIndex(m => m.route === currentPath),
            nextMenuItem = this.menuItems[currentIndex + 1] || this.menuItems[0];

        this.goTo(nextMenuItem.route);
    }

    goTo(destination) {
        const currentPath = this.props.location.pathname;
        this.props.history.push(currentPath !== destination ? destination : currentPath);
        this.props.closeMenu();
    }
}

function bindAction(dispatch) {
    return {
        openMenu: () => dispatch(actions.openMenu()),
        closeMenu: () => dispatch(actions.closeMenu())
    };
}

const mapStateToProps = state => ({
    menuOpen: state.layout.menuOpen
});

export default connect(mapStateToProps, bindAction)(withRouter(Menu));
