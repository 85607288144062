import setFilters from "./logic/setFilters";
import loadWorklogs from "./logic/loadWorklogs";
import loadMeetings from "./logic/loadMeetings";
import downloadWorklogsAsCsv from "./logic/downloadWorklogsAsCsv";

const logic = [
    setFilters,
    loadWorklogs,
    loadMeetings,
    downloadWorklogsAsCsv
]
export default logic;