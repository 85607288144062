export const OPEN_CONSOLE = 'OPEN_CONSOLE';
export const CLOSE_CONSOLE = 'CLOSE_CONSOLE';

const openConsole = () => ({
    type: OPEN_CONSOLE
});

const closeConsole = () => ({
    type: CLOSE_CONSOLE
});

export const actions = {
    openConsole,
    closeConsole
};

