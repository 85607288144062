import {createLogic} from 'redux-logic';
import {
    LOAD_ALL_COMMISSIONS,
    LOAD_ALL_COMMISSIONS_SUCCESS,
    LOAD_ALL_COMMISSIONS_FAILED
} from '../action';
import {loadCommissions, loadCommissionsWithFilter} from "../../service/api";

export default createLogic({
    type: LOAD_ALL_COMMISSIONS,
    latest: true,

    processOptions: {
        dispatchReturn: true,
        successType: LOAD_ALL_COMMISSIONS_SUCCESS,
        failType: LOAD_ALL_COMMISSIONS_FAILED,
    },
    process({getState}) {
        const state = getState();
        return loadCommissions(state.auth.token);
    }
});