import {createLogic} from 'redux-logic';
import {LOGIN, LOGIN_SUCCESS, LOGIN_FAILED} from '../action';

export default createLogic({
    type: LOGIN,
    latest: true,

    processOptions: {
        dispatchReturn: true,
        successType: LOGIN_SUCCESS,
        failType: LOGIN_FAILED,
    },
    process({cfg}) {
        const query = new URLSearchParams(window.location.search),
            token = query.get('token');

        if (!token) return {};

        const json = Buffer.from(token, "base64").toString(),
            jsonObj = JSON.parse(json) || {},
            cloudId = jsonObj.c,
            accountId = jsonObj.a;

        if (!cfg.CLOUD_IDS.includes(cloudId))
            return {};

        return Buffer.from(
            JSON.stringify({
                accountId: accountId,
                secret: cfg.API_SECRET
            })).toString('base64');
    }
});