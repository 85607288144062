export function readableDuration(seconds) {
    let hours = Math.floor((seconds/3600)),
        minutes = Math.floor((seconds%3600)/60);
    return seconds > 0 ?
        `${hours >= 1 ? `${hours}h` : ""} ${minutes >= 1 ? `${minutes}m` : ""}`.trim() : "---";
}


export function reduceText(text, maxLength=25) {
    return text.length > maxLength ? `${text.slice(0, maxLength)}..` : text;
}