import React, {Component} from 'react'
import Layout from '../layout/Index';
import {
    HashRouter,
    Route,
    Switch,
    Redirect
} from 'react-router-dom'
import WorkTime from '../worktime/Index';
import Commission from '../commission/Index';
import {Result} from "antd";

export const ROUTES = {
    worktime: '/worktime',
    commission: '/commission'
};

class Index extends Component {

    render() {
        return (
            <HashRouter>
                <Layout>
                    <Switch>
                        <Redirect from='/' to={ROUTES.worktime} exact/>
                        <Route path={ROUTES.worktime} render={() => <WorkTime/>}/>
                        <Route path={ROUTES.commission} render={() => <Commission/>}/>
                        <Route component={() =>
                            <Result
                                status="403"
                                title="403"
                                subTitle="Sorry, you are not authorized to access this page."
                            />}/>
                    </Switch>
                </Layout>
            </HashRouter>
        );
    }
}

export default Index;
