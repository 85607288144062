import React, {Component} from 'react';
import {AutoComplete, Modal} from 'antd';
import {GlobalHotKeys} from "react-hotkeys";
import styles from "./Index.module.css";
import {actions} from "./redux/action";
import {actions as worktimeActions} from "../worktime/redux/action";
import {connect} from "react-redux";


class Index extends Component {

    render() {
        const {open} = this.props;

        return (
            <div>
                <Modal
                    visible={open}
                    closable={false}
                    footer={null}
                    onCancel={this.props.closeConsole}
                    destroyOnClose={true}>
                    <AutoComplete
                        className={styles.field}
                        options={
                            [
                                {value: 'csv'}
                            ]
                        }
                        filterOption={(inputValue, option) => {
                            const command = this.parseCommand(inputValue),
                                optionCommand = option.value.toLowerCase();
                            return optionCommand.startsWith(command.key)
                        }}
                        onSelect={this.executeCommand}
                        placeholder=">_"
                        autoFocus={true}
                        defaultActiveFirstOption={true}>
                    </AutoComplete>
                </Modal>
                <GlobalHotKeys
                    keyMap={{
                        CONSOLE: "shift+space"
                    }}
                    handlers={{
                        CONSOLE: () => this.props.openConsole()
                    }}/>
            </div>
        );

    }

    parseCommand = (value) => {
        const str = value.toLowerCase(),
            key = str.replace(/\s[a-z0-9]*/g, ""),
            optionStr = str.replace(/^[a-z0-9]*\s/, ""),
            withOptions = str.includes(" ");

        return {
            key: key,
            options: withOptions ? optionStr.split(" ").filter(o => o) : false
        }
    }

    executeCommand = (cmd) => {
        const command = this.parseCommand(cmd);

        switch (command.key) {
            case "csv":
                this.props.downloadWorklogsAsCsv();
                break;
            default:
                console.log("IDK: " + JSON.stringify(command));
                break;
        }

        this.setState({open: false});
    }
}

function bindAction(dispatch) {
    return {
        openConsole: () => dispatch(actions.openConsole()),
        closeConsole: () => dispatch(actions.closeConsole()),
        downloadWorklogsAsCsv: () => dispatch(worktimeActions.downloadWorklogsAsCsv())
    };
}

const mapStateToProps = state => ({
    open: state.console.open
});

export default connect(mapStateToProps, bindAction)(Index);