import React, {Component} from 'react';
import {readableDuration} from "../../helper/helper";
import styles from "./Grid.module.css";
import {Typography, Row, Col, Space, Button, Tooltip} from 'antd';
import moment from "moment";
import {CarryOutOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {mapWorklogsAndMeetingstoGrid, ROW_TYPES} from "./service/mapper";

const {Title, Text, Link} = Typography;

class Grid extends Component {

    render() {
        const {worklogs, meetings} = this.props,
            rows = mapWorklogsAndMeetingstoGrid(worklogs, meetings);

        if (!rows || rows.length === 0) {
            return (
                <Space>
                    <Text>Keine Daten gefunden. Bitte neu laden.</Text>
                </Space>
            );
        }

        return rows.map(w => this.renderRow(w));
    }

    renderRow = (row) => {
        const start = moment(row.start).format("HH:mm"),
            end = moment(row.end).format("HH:mm");

        return (
            <Row key={row.id} className={this.rowStyles(row)}>
                <Col xs={{span: 16}} md={{span: 16}} lg={{span: 8}}>
                    {this.renderTitle(row)}
                </Col>
                <Col xs={{span: 16, order: 3}} md={{span: 24, order: 4}} lg={{span: 10, order: 2}}>
                    <Text>{this.renderDescription(row)}</Text>
                </Col>
                <Col xs={{span: 8, order: 4}} md={{span: 4, order: 2}} lg={{span: 3, order: 3}}
                     className={`${styles.colTime}`}>
                    <Text>{row.group ? "" : `${start} - ${end}`}</Text>
                </Col>
                <Col xs={{span: 8, order: 2}} md={{span: 4, order: 3}} lg={{span: 3, order: 4}}
                     className={`${styles.colTime}`}>
                    {this.renderTime(row)}
                </Col>
            </Row>
        );
    }

    rowStyles = (row) => {
        return `${styles.row} ${row.type ? styles[row.type] : ""}`;
    }

    renderTitle = (row) => {
        return row.group ?
            <Title level={3}>{row.title}</Title> :
            <Link className={`${styles.title}`}
                  href={`https://${row.baseUrl}/browse/${row.key}`}
                  target="_blank"
                  rel="noreferrer">{row.title}</Link>
    }

    renderDescription = (row) => {
        switch (row.type) {
            case ROW_TYPES.MEETING:
                return (
                    <span className={`${styles.description_meeting}`}>
                        Der Button am Ende der Zeile ermöglicht die Zeitbuchung nach Abschluss des Meetings.
                    </span>
                );
            default:
                return row.description || "";
        }
    }

    renderTime = (row) => {
        switch (row.type) {
            case ROW_TYPES.MEETING:
                const duration = `${row.durationSeconds / 60}m`,
                    comment = "Smarte%20Buchung%20über%20Time%20Portal";
                return (
                    <div>
                        <Tooltip title="Start und Dauer des Meetings als Zeitbuchung im Jira eintragen">
                            <Button type={"primary"}
                                    icon={<CarryOutOutlined/>}
                                    disabled={moment().isBefore(moment(row.end))}
                                    href={`https://${row.baseUrl}/secure/CreateWorklog!default.jspa?key=${row.key}&timeLogged=${duration}&comment=${comment}`}
                                    target={"_blank"}/>
                        </Tooltip>
                    </div>
                );
            default:
                const val = readableDuration(row.durationSeconds);
                return row.group ?
                    <Title level={3}>{val}</Title> :
                    <Link className={`${styles.time}`}
                          href={`https://${row.baseUrl}/browse/${row.key}?focusedWorklogId=${row.id}`}
                          target="_blank"
                          rel="noreferrer">{val}</Link>
        }
    }

}

const mapStateToProps = state => ({
    worklogs: state.worktime.worklogs,
    meetings: state.worktime.meetings
});

export default connect(mapStateToProps, null)(Grid);
