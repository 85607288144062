import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Console from "../console/Index";


class Index extends Component {

    render() {

        return (
            <div>
                <Console/>
                {React.Children.only(this.props.children)}
            </div>
        );
    }
}

export default withRouter(Index);
