import { combineReducers } from 'redux';
import layout from '../modules/layout/redux/reducer';
import console from '../modules/console/redux/reducer';
import auth from '../modules/auth/redux/reducer';
import worktime from '../modules/worktime/redux/reducer';
import commission from '../modules/commission/redux/reducer';


export default combineReducers({
	layout,
	console,
	auth,
	worktime,
	commission
})
