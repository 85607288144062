import moment from "moment";
import {
    SET_WORKTIME_FILTERS_SUCCESS,
    LOAD_WORKLOGS, LOAD_WORKLOGS_SUCCESS, LOAD_WORKLOGS_FAILED,
    LOAD_MEETINGS, LOAD_MEETINGS_SUCCESS, LOAD_MEETINGS_FAILED,
} from './action';

const initialState = {
    filterFrom: moment().startOf("day"),
    filterUntil: moment().endOf("day"),
    worklogs: [],
    meetings: [],
    loading: false
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        /*
         * FILTERS
         */
        case SET_WORKTIME_FILTERS_SUCCESS:
            const {filterFrom, filterUntil} = action.payload;
            state = {...state, filterFrom, filterUntil};
            break;


        /*
         * WORKLOGS
         */
        case LOAD_WORKLOGS:
            state = {
                ...state,
                loading: true,
                worklogs: []
            };
            break;
        case LOAD_WORKLOGS_SUCCESS:
            state = {
                ...state,
                loading: false,
                worklogs: action.payload
            };
            break;
        case LOAD_WORKLOGS_FAILED:
            state = {...state, loading: false};
            break;


        /*
         * MEETINGS
         */
        case LOAD_MEETINGS:
            state = {
                ...state,
                loading: true,
                meetings: []
            };
            break;
        case LOAD_MEETINGS_SUCCESS:
            state = {
                ...state,
                loading: false,
                meetings: action.payload
            };
            break;
        case LOAD_MEETINGS_FAILED:
            state = {...state, loading: false};
            break;
        default:
            break;
    }
    return state;
}