import React, {Component} from 'react';
import {readableDuration} from "../../helper/helper";
import styles from "./Grid.module.css";
import {Typography, Row, Col, Space} from 'antd';
import {connect} from "react-redux";

const {Title, Text, Link} = Typography;

class Grid extends Component {

    render() {
        const {commissions} = this.props;

        if (!commissions || commissions.length === 0) {
            return (
                <Space>
                    <Text>Keine Daten gefunden.</Text>
                </Space>
            );
        }

        return commissions.map(w => this.renderCommission(w));
    }

    renderCommission = (commission) => {
        return (
            <div>
                <Row key={commission.id} className={styles.row}>
                    <Col xs={24} md={12}>
                        {this.renderTitle(commission, true)}
                    </Col>
                    <Col xs={24} md={12}>
                        <Row>
                            <Col xs={8} className={`${styles.colTime}`}>
                                {this.renderWorkTime(commission.timeSpentSeconds, commission, true)}
                            </Col>
                            <Col xs={8} className={`${styles.colTime}`}>
                                {this.renderWorkTime(
                                    commission.sumTimeSpentSeconds - commission.timeSpentSeconds,
                                    commission,
                                    true)}
                            </Col>
                            <Col xs={8} className={`${styles.colTime}`}>
                                {this.renderWorkTime(commission.sumTimeSpentSecondsTree, commission, true)}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {commission.children.map(partial =>
                    <Row key={partial.id} className={`${styles.row} ${styles.partial}`}>
                        <Col xs={24} md={12}>
                            {this.renderTitle(partial)}
                        </Col>
                        <Col xs={24} md={12}>
                            <Row>
                                <Col xs={8} className={`${styles.colTime}`}>
                                    {this.renderWorkTime(partial.timeSpentSeconds, partial)}
                                </Col>
                                <Col xs={8} className={`${styles.colTime}`}>
                                    {this.renderWorkTime(partial.sumTimeSpentSeconds - partial.timeSpentSeconds, partial)}
                                </Col>
                                <Col xs={8} className={`${styles.colTime}`}>
                                    {this.renderWorkTime(partial.sumTimeSpentSeconds, partial)}
                                </Col>
                            </Row>
                        </Col>
                    </Row>)}
            </div>
        );
    }

    renderTitle = (row, isParent) => {
        const text = `[${row.issue.key}] ${row.issue.name}`;
        return (
            <Link className={`${styles.title}`}
                  href={`https://${row.platform.baseUrl}/browse/${row.issue.key}`}
                  target="_blank"
                  rel="noreferrer">
                {isParent ? <Title level={3}>{text}</Title> : text}
            </Link>
        );
    }

    renderWorkTime = (seconds, row, isParent) => {
        const text = readableDuration(seconds);
        return (
            <Link className={`${styles.time}`}
                  href={`https://${row.platform.baseUrl}/browse/${row.issue.key}?focusedWorklogId=-1`}
                  target="_blank"
                  rel="noreferrer">
                {isParent ? <Title level={3}>{text}</Title> : text}
            </Link>
        );
    }

}

const mapStateToProps = state => ({
    commissions: state.commission.commissionWithWorktime
});

export default connect(mapStateToProps, null)(Grid);
