import React, {Component} from 'react';
import {actions} from "./redux/action";
import {connect} from "react-redux";
import moment from "moment";
import {Button} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';
import Header from "../layout/Header";
import {DateRangePicker, DateRangePickerMobile} from "../_partial/RangePicker";
import styles from "./Header.module.css";


class Head extends Component {

    componentDidMount() {
        this.props.setFilters();
    }

    render() {
        const {from, until, setFilters} = this.props;

        return (
            <Header
                desktop={
                    <div>
                        <DateRangePicker
                            key={"rangePicker"}
                            className={`${styles.btn}`}
                            value={[moment(from), moment(until)]}
                            onCalendarChange={(dates) => {
                                const dateArr = dates || [],
                                    from = dateArr[0] ? moment(dateArr[0]).startOf("day") : null,
                                    until = dateArr[1] ? moment(dateArr[1]).endOf("day") : null;
                                setFilters(from, until)
                            }}/>
                        {this.getReloadBtn()}
                    </div>
                }
                mobile={
                    <div>
                        <DateRangePickerMobile
                            className={`${styles.btn}`}
                            from={from}
                            until={until}
                            onClick={(from, until) => setFilters(from, until)}/>
                        {this.getReloadBtn()}
                    </div>}/>
        );

    }

    getReloadBtn = () => {
        const {from, until, loading, setFilters} = this.props,
            btnProps = {};

        if (loading)
            btnProps.loading = true;

        return (
            <Button key={"reloadBtn"}
                    className={`${styles.btn}`}
                    {...btnProps}
                    icon={<ReloadOutlined/>}
                    onClick={() => setFilters(from, until)}/>
        );
    }
}

function bindAction(dispatch) {
    return {
        setFilters: (from, until) => dispatch(actions.setFilters(from, until))
    };
}

const mapStateToProps = state => ({
    from: state.worktime.filterFrom,
    until: state.worktime.filterUntil,
    loading: state.worktime.loading
});

export default connect(mapStateToProps, bindAction)(Head);
