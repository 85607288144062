import moment from "moment";
import {
    SET_COMMISSION_FILTERS_SUCCESS,
    LOAD_ALL_COMMISSIONS,
    LOAD_ALL_COMMISSIONS_SUCCESS,
    LOAD_ALL_COMMISSIONS_FAILED,
    LOAD_FILTERED_COMMISSIONS,
    LOAD_FILTERED_COMMISSIONS_SUCCESS,
    LOAD_FILTERED_COMMISSIONS_FAILED
} from './action';

const initialState = {
    filterIds: [],
    filterFrom: moment().startOf("day"),
    filterUntil: moment().endOf("day"),
    commissions: [],
    commissionWithWorktime: [],
    loading: false
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        /*
         * FILTERS
         */
        case SET_COMMISSION_FILTERS_SUCCESS:
            const {filterIds, filterFrom, filterUntil} = action.payload;
            state = {...state, filterIds, filterFrom, filterUntil};
            break;


        /*
         * LOAD ALL
         */
        case LOAD_ALL_COMMISSIONS:
            state = {
                ...state,
                loading: true,
                commissions: []
            };
            break;
        case LOAD_ALL_COMMISSIONS_SUCCESS:
            state = {
                ...state,
                loading: false,
                commissions: action.payload
            };
            break;

        /*
         * LOAD ALL
         */
        case LOAD_FILTERED_COMMISSIONS:
            state = {
                ...state,
                loading: true,
                commissionWithWorktime: []
            };
            break;
        case LOAD_FILTERED_COMMISSIONS_SUCCESS:
            state = {
                ...state,
                loading: false,
                commissionWithWorktime: action.payload
            };
            break;


        case LOAD_ALL_COMMISSIONS_FAILED:
        case LOAD_FILTERED_COMMISSIONS_FAILED:
            state = {...state, loading: false};
            break;

        default:
            break;
    }
    return state;
}