import React, {Component} from 'react';
import Grid from "./Grid";
import Header from "./Header";


class Index extends Component {

    render() {
        return (
            <div>
                <Header/>
                <Grid/>
            </div>
        );
    }

}

export default Index;
