export function loadWorklogs(token, from, until) {
    return fetch(`/api/worklogs/?from=${from.toISOString()}&until=${until.toISOString()}`, {
        method: 'GET',
        credentials: 'omit',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((res) => {
        return res.json()
    }).catch(e => {
        return [];
    });
}

export function loadMeetings(token, from, until) {
    return fetch(`/api/meetings/bookable?from=${from.toISOString()}&until=${until.toISOString()}`, {
        method: 'GET',
        credentials: 'omit',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((res) => {
        return res.json()
    }).catch(e => {
        return [];
    });
}

export function downloadWorklogsAsCsv(token, from, until) {
    return fetch(`/api/worklogs/csv/?from=${from.toISOString()}&until=${until.toISOString()}`, {
        method: 'GET',
        credentials: 'omit',
        headers: {
            'Accept': 'text/csv',
            'Authorization': token
        }
    }).then((res) => {
        if(!res.ok) throw res.statusText;
        return res.text();
    }).then((res) =>
        res.replace("\r\n", "\n").replace("\n", "\r\n")
    ).then((res) => {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = `data:text/csv;charset=utf-8,${res}`;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'download.csv';
        hiddenElement.click();
    }).catch(e => {
        console.log(e);
    });

}
