import {createLogic} from 'redux-logic';
import {
    SET_COMMISSION_FILTERS_SUCCESS,
    LOAD_FILTERED_COMMISSIONS_SUCCESS,
    LOAD_FILTERED_COMMISSIONS_FAILED
} from '../action';
import {loadCommissionsWithFilter} from "../../service/api";

export default createLogic({
    type: SET_COMMISSION_FILTERS_SUCCESS,
    latest: true,

    processOptions: {
        dispatchReturn: true,
        successType: LOAD_FILTERED_COMMISSIONS_SUCCESS,
        failType: LOAD_FILTERED_COMMISSIONS_FAILED,
    },
    process({getState}) {
        const state = getState(),
            ids = state.commission.filterIds,
            from = state.commission.filterFrom,
            until = state.commission.filterUntil;

        return loadCommissionsWithFilter(state.auth.token, ids, from, until);
    }
});